<template>
  <v-dialog
    v-model="dialog"
    max-width="80%"
    @click:outside="cancel()"
    @keydown.esc="cancel()"
  >
    <v-card class="pa-4">
      <v-row>
        <v-col cols="12">
          <span>เพิ่ม{{ $t("non_im.affilation") }}</span>
        </v-col>
        <!-- <v-col cols="12" md="6">
          <span>student Type</span>
          <v-autocomplete
            v-model="form.mStudentTypeId"
            :items="mStudentType"
            :item-text="EngLang ? 'name_en' : 'name_th'"
            item-value="id"
            solo
            dense
            @change="getAllSub_StudentType(form.mStudentTypeId)"
          ></v-autocomplete>
        </v-col> -->
        <!-- <v-col cols="12" md="6">
          <span>Sub Student Type</span>
          <v-autocomplete
            v-model="form.mSubCategoryOfStudentTypeId"
            :items="Sub_mStudentType"
            :item-text="EngLang ? 'name_en' : 'name_th'"
            item-value="id"
            solo
            dense
          ></v-autocomplete>
        </v-col> -->
        <v-col cols="12" md="6">
          <span>{{ $t("non_im.fieldnameTH") }}</span>
          <v-text-field v-model="form.name_th" solo dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>{{ $t("non_im.fieldnameEN") }}</span>
          <v-text-field v-model="form.name_en" solo dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>อีเมลส่วนกลาง</span>
          <v-text-field
            v-model="form.email"
            solo
            dense
            :rules="rules.emailRules"
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>FAX</span>
          <v-text-field v-model="form.faxNumber" solo dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>ที่อยู่คณะภาษาไทย</span>
          <v-text-field v-model="form.address_th" solo dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>ที่อยู่คณะภาษาอังกฤษ</span>
          <v-text-field v-model="form.address_en" solo dense></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>รหัสสารบัญภาษาไทย</span>
          <v-text-field
            v-model="form.id_content_list_th"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>รหัสสารบัญภาษาอังกฤษ</span>
          <v-text-field
            v-model="form.id_content_list_en"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>ชื่อ-นามสกุล คณบดี/ผู้อำนวยการ (ไทย)</span>
          <v-text-field
            v-model="form.dean_director_th"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>ชื่อ-นามสกุล คณบดี/ผู้อำนวยการ (อังกฤษ)</span>
          <v-text-field
            v-model="form.dean_director_en"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>ชื่อ-นามสกุล รองคณบดี/รองผู้อำนวยการ (ไทย)</span>
          <v-text-field
            v-model="form.act_instead_agent_th"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6">
          <span>ชื่อ-นามสกุล รองคณบดี/รองผู้อำนวยการ (อังกฤษ)</span>
          <v-text-field
            v-model="form.act_instead_agent_en"
            solo
            dense
          ></v-text-field>
        </v-col>

        <!-- <v-col cols="12" md="12">
          <span>คําสั่งแต่งต้ังผู้ปฏิบัติการแทน</span>
          <v-radio-group v-model="form.is_agent" row solo dense>
            <v-radio :value="true" label="Yes"></v-radio>
            <v-radio :value="false" label="No"></v-radio>
          </v-radio-group>
        </v-col>

        <v-col cols="12" md="6" v-if="form.is_agent == true">
          <span>ตำแหน่ง (ไทย)</span>
          <v-text-field
            v-model="form.position_agent_th"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="form.is_agent == true">
          <span>ตำแหน่ง (อังกฤษ)</span>
          <v-text-field
            v-model="form.position_agent_en"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="form.is_agent == true">
          <span>ผู้ปฏิบัติการแทน (ไทย)</span>
          <v-text-field
            v-model="form.act_instead_agent_th"
            solo
            dense
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="6" v-if="form.is_agent == true">
          <span>ผู้ปฏิบัติการแทน (อังกฤษ)</span>
          <v-text-field
            v-model="form.act_instead_agent_en"
            solo
            dense
          ></v-text-field>
        </v-col> -->

        <v-col cols="12">
          <v-btn v-if="isEdit" @click="editdata()" dark color="primary"
            >แก้ไข
          </v-btn>
          <v-btn v-else @click="submit()" dark color="primary">บันทึก </v-btn>
          <v-btn @click="cancel()">ยกเลิก</v-btn>
        </v-col>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import { Decode } from "@/services";
export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    form: Object,
    EngLang: Boolean,
    mStudentType: Array,
  },
  data() {
    return {
      dialog: false,
      form: {
        name_th: "",
        name_en: "",
      },
      Sub_mStudentType: [],
      rules: {
        emailRules: [
          (v) => !!v || "E-mail is required",
          (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        selectRules: [(val) => !!val || "This field is required"],
      },
    };
  },
  watch: {
    items: {
      handler(val) {
        if (this.isEdit === true) {
          //   this.getRewardById(val.id);
          //   console.log(val.id);
          // this.form =
        }
      },
      deeps: true,
    },
  },
  methods: {
    // form.mStudentTypeId
    async getAllSub_StudentType(mStudentTypeId) {
      const response = await this.axios.get(
        `${process.env.VUE_APP_API}/subCategoryOfStudentType?mStudentTypeId=${mStudentTypeId}`
      );
      console.log("response", response.data.data);
      this.Sub_mStudentType = response.data.data;
    },

    async submit() {
      // if (this.$refs.createForm.validate(true)) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const data = {
        ...this.form,
      };
      const response = await this.axios.post(
        `${process.env.VUE_APP_API}/affiliation_faculty`,
        // data,
        this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSuccessMaster"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.form = {};
        // this.dialog = false;
        this.$emit("closeDialog");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    async editdata() {
      // if (this.$refs.createForm.validate(true)) {
      this.userData = JSON.parse(
        Decode.decode(localStorage.getItem("userCUdata"))
      );
      console.log("thisuserData", this.userData);
      const auth = {
        headers: {
          Authorization: `Bearer ${this.userData.token}`,
        },
      };
      const data = {
        ...this.form,
      };
      const id = this.$route.query.id;
      const response = await this.axios.put(
        `${process.env.VUE_APP_API}/affiliation_faculty/${this.form.id}`,
        // data,
        this.form,
        auth
      );
      console.log("createCate", response);
      console.log(response.data.response_status);
      if (response.data.response_status == "SUCCESS") {
        this.$swal.fire({
          icon: "success",
          text: this.$t("status.submitSuccessMaster"),
          showConfirmButton: false,
          timer: 1500,
        });
        // this.form = {};
        // this.dialog = false;
        this.$emit("closeDialog");
      } else {
        this.$swal.fire({
          icon: "error",
          text: this.$t("status.submitUnsuccess"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
      // }
    },
    cancel() {
      this.$emit("closeDialog");
    },
  },
};
</script>